import React from "react"

export const SectionHeader = ({
  sectionTitle,
  headline,
  description,
}: {
  sectionTitle: string
  headline: string
  description: string
}) => (
  <div>
    <h2 className="text-base font-semibold tracking-wider text-cyan-600 uppercase">
      {sectionTitle}
    </h2>
    <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
      {headline}
    </p>
    <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
      {description}
    </p>
  </div>
)
