import React from "react"
import { ExternalLinkIcon } from "@heroicons/react/solid"
import { StaticImage } from "gatsby-plugin-image"

export default function Contact() {
  return (
    <div className="relative bg-gray-900" id="contact">
      <div className="relative h-56 bg-teal-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        <StaticImage
          className="w-full h-full object-cover"
          src="../../images/resources/persons.jfif"
          alt=""
        />
        <div
          aria-hidden="true"
          className="absolute inset-0 bg-gradient-to-r from-teal-500 to-cyan-600 mix-blend-multiply"
        />
      </div>
      <div className="relative mx-auto max-w-md px-4 py-12 sm:max-w-7xl sm:px-6 sm:py-20 md:py-28 lg:px-8 lg:py-32">
        <div className="md:ml-auto md:w-1/2 md:pl-10">
          <h2 className="text-base font-semibold uppercase tracking-wider bg-clip-text text-transparent bg-gradient-to-r from-teal-200 to-cyan-400">
            Contact
          </h2>
          <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">
            Let's work together
          </p>
          <p className="mt-3 text-lg text-gray-300">
            We’d love to hear from you! Send us a message if you have questions,
            feedback or anything.
          </p>
          <div className="mt-8">
            <div className="inline-flex rounded-md shadow">
              <a
                href="mailto:office@nimmervoll.work"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
              >
                Contact by email
                <ExternalLinkIcon
                  className="-mr-1 ml-3 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
