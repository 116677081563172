import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { SectionHeader } from "../common/SectionHeader"

const blogPosts = [
  {
    id: 1,
    title: "Amazon Quantum Ledger Database (QLDB)",
    href: "https://aws.amazon.com/qldb/",
    date: "2021",
    datetime: "2021-01-01",
    category: { name: "Website", href: "https://aws.amazon.com/qldb/" },
    renderImage: () => (
      <StaticImage
        className="h-48 w-full object-cover"
        src="../../images/resources/blockchain.jfif"
        alt="Blockchain"
      />
    ),
    // imageUrl:
    //   "https://images.unsplash.com/photo-1561451213-d5c9f0951fdf?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2089&q=80",
    preview:
      "Amazon QLDB is a fully managed ledger database that provides a transparent, immutable, and cryptographically verifiable transaction log ‎owned by a central trusted authority. Amazon QLDB can be used to track each and every application data change and maintains a complete and verifiable history of changes over time.",
    author: {
      name: "Amazon Web Services",
      imageUrl: "",
      href: "https://aws.amazon.com/",
    },
    readingLength: "8 min",
  },
  {
    id: 2,
    title: "AWS Well-Architected Framework - SaaS Lens",
    href: "https://docs.aws.amazon.com/wellarchitected/latest/saas-lens/saas-lens.html",
    date: "Dec 3, 2020",
    datetime: "2020-12-03",
    category: {
      name: "Whitepaper",
      href: "https://docs.aws.amazon.com/wellarchitected/latest/saas-lens/wellarchitected-saas-lens.pdf#saas-lens",
    },
    renderImage: () => (
      <StaticImage
        className="h-48 w-full object-cover"
        src="../../images/resources/buildings.jfif"
        alt="Buildings"
      />
    ),
    // imageUrl:
    //   "https://images.unsplash.com/photo-1516617539902-51315fafbaa7?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1651&q=80",
    preview:
      "This paper describes the SaaS Lens for the AWS Well-Architected Framework, which enables customers to review and improve their cloud-based architectures and better understand the business impact of their design decisions.",
    author: {
      name: "Amazon Web Services",
      imageUrl: "",
      href: "https://aws.amazon.com/",
    },
    readingLength: "90 min",
  },
  {
    id: 3,
    title: "What is AWS CDK, and why should your DevOps teams use it?",
    href: "https://www.nclouds.com/blog/what-is-aws-cdk-and-why-should-your-devops-teams-use-it/",
    date: "Apr 2021",
    datetime: "2020-04-01",
    category: {
      name: "Article",
      href: "https://www.nclouds.com/blog/what-is-aws-cdk-and-why-should-your-devops-teams-use-it/",
    },
    renderImage: () => (
      <StaticImage
        className="h-48 w-full object-cover"
        src="../../images/resources/cdk.jpg"
        alt="AWS Cloud Development Kit"
      />
    ),
    // imageUrl: "https://www.nclouds.com/blog/wp-content/uploads/2021/03/v2.jpg",
    preview:
      "AWS CDK is an open-source software development framework for defining cloud infrastructure as code (IaC) AWS introduced in July 2019. Because AWS CDK uses CloudFormation as a foundation.",
    author: {
      name: "Daniela Metz",
      imageUrl: "",
      href: "https://www.nclouds.com/blog/author/ronaldo-ruiz/",
    },
    readingLength: "11 min",
  },
]

export default function Resources() {
  return (
    <div id="resources">
      <div className="relative bg-gray-50 pb-16">
        <div className="relative">
          {/* <div className="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
            <SectionHeader
              sectionTitle="Technology"
              headline="Resources"
              description="Some material about the technology used behind the scenes."
            />
          </div> */}
          <div className="bg-gray-50 pt-12 sm:pt-16">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="max-w-4xl mx-auto text-center">
                <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                  Trusted by developers from over 80 planets
                </h2>
                <p className="mt-3 text-xl text-gray-500 sm:mt-4">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Repellendus repellat laudantium.
                </p>
              </div>
            </div>
            <div className="mt-10 pb-12 bg-gray-50 sm:pb-16">
              <div className="relative">
                <div className="absolute inset-0 h-1/2 bg-gray-50" />
                <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                  <div className="max-w-4xl mx-auto">
                    <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
                      <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                        <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                          Coverage
                        </dt>
                        <dd className="order-1 text-5xl font-extrabold text-teal-500">
                          100%
                        </dd>
                      </div>
                      <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                        <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                          Reliability
                        </dt>
                        <dd className="order-1 text-5xl font-extrabold text-teal-500">
                          24/7
                        </dd>
                      </div>
                      <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                        <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                          Weekly downloads
                        </dt>
                        <dd className="order-1 text-5xl font-extrabold text-teal-500">
                          100k+
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
