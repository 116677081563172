import React from "react"
import {
  CloudUploadIcon,
  CodeIcon,
  LockClosedIcon,
  SwitchVerticalIcon,
  CloudIcon,
  CubeTransparentIcon,
} from "@heroicons/react/outline"
import { SectionHeader } from "../common/SectionHeader"
const incentives = [
  {
    name: "Speed Up Build-Time",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-shipping-simple.svg",
    description:
      "Start by eliminating dependecies with Glutamate.js and save lot's of precious resources.",
  },
  {
    name: "Maximize Code Coverage",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-warranty-simple.svg",
    description:
      "We are offering a library with 99% coverage and utilities to push you coverage to the maximum.",
  },
  {
    name: "Universal",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-exchange-simple.svg",
    description:
      "Glutamate.js behaves like drag n' drop, - use it in any language.",
  },
]
const features = [
  {
    name: "Plug 'n Play",
    description: "Install it with a single command and make your life easier.",
    icon: CloudUploadIcon,
  },
  {
    name: "Pure",
    description:
      "Glutamate.js made sure to stay away from other nasty bloated libraries and frameworks.",
    icon: LockClosedIcon,
  },
  {
    name: "Exclusively Maintained",
    description:
      "Developed by the two japanese Biochemist Lewnimmer MSc and Vollwel PhD. under laboratory conditions",
    icon: SwitchVerticalIcon,
  },
  {
    name: "Seamless integration",
    description:
      "Glutamate.js balances, blends, and rounds the perception of any project.",
    icon: CubeTransparentIcon,
  },
  {
    name: "Powerful API",
    description:
      "Architected using extraterrestrial concepts. This allowed us to showcase uncomparable performance in contrast to any other library.",
    icon: CodeIcon,
  },
  {
    name: "Cloud dominating",
    description:
      "It even runs in the cloud, powering most of the public cloud sector.",
    icon: CloudIcon,
  },
]

export default function Feature() {
  return (
    <>
      {/* Feature section with screenshot */}
      <div
        className="relative bg-gray-50 pt-16 sm:pt-24 lg:pt-32"
        id="features"
      >
        <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
          <SectionHeader
            sectionTitle="Cross Platform"
            headline="Adopt the framework in any platform"
            description="Build. Test. Release. Maintain. With a true cross-platform architecture, Glutamate.js gives you the power to craft complete apps for any device. This also means that you get all of our tools and services designed to make the process easy, effective, and even enjoyable!"
          />
        </div>
      </div>

      <div className="">
        <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
          <div className="">
            <div className="max-w-7xl mx-auto py-24 sm:px-2 sm:py-32 lg:px-4">
              <div className="max-w-2xl mx-auto px-4 lg:max-w-none">
                <div className="text-left mt-16 grid grid-cols-1 gap-y-10 gap-x-8 lg:grid-cols-3">
                  {incentives.map(incentive => (
                    <div key={incentive.name} className="sm:flex lg:block">
                      <div className="sm:flex-shrink-0">
                        <img
                          className="w-16 h-16"
                          src={incentive.imageSrc}
                          alt=""
                        />
                      </div>
                      <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                        <h3 className="text-sm font-medium text-gray-900">
                          {incentive.name}
                        </h3>
                        <p className="mt-2 text-sm text-gray-500">
                          {incentive.description}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-black">
        <div className="px-8 pt-24 pb-32" style={{ fontFamily: "Consolas" }}>
          <div className="grid gap-8 items-start justify-center">
            <p className="mt-0 mb-8 text-white text-3xl font-extrabold tracking-tight sm:text-4xl font-sans">
              It's that simple
            </p>
            <div className="relative group">
              <div className="filter absolute -inset-1 bg-gradient-to-r from-pink-600 to-purple-600 rounded-lg blur-md opacity-75 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-tilt"></div>
              <div className="relative px-7 py-4 bg-black rounded-lg leading-none flex items-center divide-x divide-gray-600">
                <span className="flex items-center space-x-5">
                  {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-pink-600 -rotate-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z" />
          </svg> */}
                  <span className="pr-6 text-gray-100 text-xl">
                    <span style={{ color: "#C586C0" }}>import</span>
                    <span style={{ color: "#D4D4D4" }}> {"{"} </span>
                    <span style={{ color: "#9CDCFE" }}> {" flavour "} </span>
                    <span style={{ color: "#D4D4D4" }}> {"}"} </span>
                    <span style={{ color: "#C586C0" }}>from</span>{" "}
                    <span style={{ color: "#CE9178" }}>"glutamate"</span>
                  </span>
                </span>
                {/* <span className="pl-6 text-indigo-400 group-hover:text-gray-100 transition duration-200">
                  It's that easy &rarr;
                </span> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Feature section with grid */}
      <div className="relative bg-white py-16 sm:py-24 lg:py-32" id="flavours">
        <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
          <SectionHeader
            sectionTitle="Flavours"
            headline="Write mouth-watering and delicious code"
            description="The basic sensory function of Glutamate.js is attributed to its ability to enhance savory taste-active compounds when added in the proper code constructs."
          />
          <div className="mt-12">
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {features.map(feature => (
                <div key={feature.name} className="pt-6">
                  <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center p-3 bg-gradient-to-r from-teal-500 to-cyan-600 rounded-md shadow-lg">
                          <feature.icon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                        {feature.name}
                      </h3>
                      <p className="mt-5 text-base text-gray-500">
                        {feature.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
